import { schema } from 'normalizr';

export const GOAL_SCHEMA = new schema.Entity('goals', {}, { idAttribute: '@id' });
export const INCIDENT_TYPE_SCHEMA = new schema.Entity('incidentTypes', {}, { idAttribute: '@id' });
export const MOTIVATION_SCHEMA = new schema.Entity('motivations', {}, { idAttribute: '@id' });
export const NOTE_SCHEMA = new schema.Entity('notes', {}, { idAttribute: '@id' });

export default {
  Goal: GOAL_SCHEMA,
  IncidentType: INCIDENT_TYPE_SCHEMA,
  Motivation: MOTIVATION_SCHEMA,
  Note: NOTE_SCHEMA
};
