import React, { ComponentType } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container, Link as MaterialLink, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Grid from 'app/components/Grid';
import { useConfig } from 'app/hooks';

const useStyles = makeStyles(theme => ({
  footer: {
    color: '#fff'
  },
  footerLink: {
    ...theme.typography.body2,
    color: theme.palette.getContrastText(theme.palette.grey[900]),
    textDecoration: 'none',
    transition: theme.transitions.create('color'),
    '&&:hover': {
      color: theme.palette.primary.light
    }
  },
  footerTitle: {
    color: (props: any) =>
      props.footerColor === 'grey.900' ? theme.palette.primary.light : theme.palette.getContrastText(props.footerColor)
  },
  logo: {
    maxHeight: '80px',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));

const Footer: ComponentType = () => {
  const theme = useConfig(config => config.theme);
  const footer = useConfig(config => config.footer);
  const isLegalNoticeActive = useConfig(config => config.legalNotice.active);
  const menu = useConfig(config => config.menu);

  const props = { footerColor: theme.footerColor || 'grey.900' };
  const classes = useStyles(props);

  return (
    <Box component="footer" py={3} bgcolor={theme.footerColor || 'grey.900'} mt="auto">
      <Container maxWidth="lg">
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
          alignItems="center"
          className={classes.footer}
        >
          <div>
            <Typography variant="h6" color="inherit" paragraph className={classes.footerTitle}>
              LIENS UTILES
            </Typography>
            <Grid xspacing={2} yspacing={1} columns={1}>
              <Link className={classes.footerLink} to={{ pathname: '/our-service', state: { withPrev: true } }}>
                {menu.service}
              </Link>
              <Link className={classes.footerLink} to={{ pathname: '/our-offers', state: { withPrev: true } }}>
                {menu.offers}
              </Link>
              <Link className={classes.footerLink} to={{ pathname: '/our-boxes', state: { withPrev: true } }}>
                {menu.boxes}
              </Link>
              <Link className={classes.footerLink} to={{ pathname: '/our-rules', state: { withPrev: true } }}>
                CGAU
              </Link>
              {isLegalNoticeActive && (
                <Link className={classes.footerLink} to={{ pathname: '/legal-notice', state: { withPrev: true } }}>
                  Mentions légales
                </Link>
              )}
              <Link className={classes.footerLink} to={{ pathname: '/contact', state: { withPrev: true } }}>
                Contactez-nous
              </Link>
            </Grid>
          </div>
          {footer.contact && (
            <div>
              <Typography variant="h6" color="inherit" paragraph className={classes.footerTitle}>
                NOUS CONTACTER
              </Typography>
              <Grid yspacing={1} columns={1}>
                {footer.phone && (
                  <Typography variant="body2" gutterBottom>
                    Tel : {footer.phone}
                  </Typography>
                )}
                {footer.mail && (
                  <MaterialLink
                    className={classes.footerLink}
                    href={'mailto:' + footer.mail}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {footer.mail}
                  </MaterialLink>
                )}
                {footer.address && (
                  <div>
                    <Typography variant="body2">{footer.address.name}</Typography>
                    <Typography variant="body2">{footer.address.address}</Typography>
                    <Typography variant="body2">
                      {footer.address.postal_code} {footer.address.city}
                    </Typography>
                  </div>
                )}
              </Grid>
            </div>
          )}
          {theme.logoFooter && (
            <>
              {footer.logo_url ? (
                <a href={footer.logo_url} rel="noopener noreferrer" target="_blank">
                  <img src={theme.logoFooter} alt="Logo" className={classes.logo} />
                </a>
              ) : (
                <img src={theme.logoFooter} alt="Logo" className={classes.logo} />
              )}
            </>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
