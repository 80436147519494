import { schema } from 'normalizr';

const CLUB_SCHEMA = new schema.Entity('clubs', {}, { idAttribute: '@id' });
const STUDIO_SCHEMA = new schema.Entity('studios', {}, { idAttribute: '@id' });
const ACTIVITY_SCHEMA = new schema.Entity('activities', {}, { idAttribute: '@id' });
const COACH_SCHEMA = new schema.Entity('coaches', {}, { idAttribute: '@id' });
const CLASS_EVENT_SCHEMA = new schema.Entity('classEvents', {}, { idAttribute: '@id' });
const ATTENDEE_SCHEMA = new schema.Entity('attendees', {}, { idAttribute: '@id' });

CLASS_EVENT_SCHEMA.define({
  club: CLUB_SCHEMA,
  studio: STUDIO_SCHEMA,
  activity: ACTIVITY_SCHEMA,
  coach: COACH_SCHEMA,
  bookedAttendees: [ATTENDEE_SCHEMA]
});

export default {
  Activity: ACTIVITY_SCHEMA,
  ClassEvent: CLASS_EVENT_SCHEMA,
  Attendee: ATTENDEE_SCHEMA
};
