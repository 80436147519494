import React, { ComponentType, useMemo } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme } from '@material-ui/core/styles';
import { useConfig } from 'app/hooks';

const defaultTheme = createMuiTheme();

export const withThemeProvider = (WrappedComponent: ComponentType): ComponentType => props => {
  const theme = useConfig(config => config.theme);
  const muiTheme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          text: {
            primary: theme.textColor || defaultTheme.palette.text.primary
          },
          primary: { main: theme.primaryColor },
          secondary: { main: theme.secondaryColor || theme.primaryColor },
          contrastThreshold: 2,
          background: {
            default: '#f3f3f3'
          }
        },
        typography: {
          fontFamily: 'Montserrat, Helvetica, Arial, sans-serif;',
          h1: {
            lineHeight: 1.1
          },
          h2: {
            lineHeight: 1.1
          },
          h3: {
            lineHeight: 1.1
          },
          h4: {
            lineHeight: 1.1
          },
          h5: {
            lineHeight: 1.1
          },
          h6: {
            lineHeight: 1.1
          },
          body1: {},
          body2: {
            lineHeight: 1.2
          }
        },
        props: {
          MuiAppBar: {
            color: 'default'
          },
          MuiTextField: {
            variant: 'outlined',
            margin: 'normal',
            InputLabelProps: {
              shrink: true
            }
          }
        },
        overrides: {
          MuiAppBar: {
            colorDefault: {
              backgroundColor: theme.headerColor || '#FFF'
            }
          },
          MuiButton: {
            contained: {
              backgroundColor: '#FFF'
            }
          }
        }
      }),
    [theme.headerColor, theme.primaryColor, theme.secondaryColor, theme.textColor]
  );

  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      <WrappedComponent {...props} />
    </ThemeProvider>
  );
};

export default withThemeProvider;
