import compose from 'app/hoc';
import withHookProviders from 'app/hooks';
import withThemeProvider from './withThemeProvider';

const withProviders = compose(
  withHookProviders,
  withThemeProvider
);

export default withProviders;
