import { useCallback } from 'react';
import createPersistedState from 'use-persisted-state';
import jwtDecode from 'jwt-decode';
import useConfig from './useConfig';
import makeOauthClient from './services/makeOauthClient';
import createProvider from './createProvider';

const emptyAuthentication = {
  grantType: null,
  value: undefined
};

type GrantType = 'authorization_code' | 'client_credentials' | 'refresh_token' | 'password';
type TokenType = {
  access_token?: string;
  refresh_token?: string;
  expires_in?: number;
  scope?: string;
  token_type?: 'bearer';
};
type AuthenticationType = { grantType: GrantType; value: TokenType } | typeof emptyAuthentication;

type JWTTokenType = { exp: number; iat: string; jti: string } & {
  clientToken: string;
  club?: string;
  email: string;
  roles: string[];
  targetId?: string;
};

export const getIsAccessTokenValid = (accessToken?: string, secondsBeforeRefresh = 0) => {
  if (typeof accessToken !== 'string') return false;

  const jwtContent = jwtDecode<JWTTokenType>(accessToken);

  const now = Math.floor(new Date().valueOf() / 1000);
  const remainingLifetime = jwtContent.exp - now - secondsBeforeRefresh;

  return remainingLifetime > 0;
};

const usePersistedState = createPersistedState('authentication');

const useAuthenticationState = () => {
  const clientToken = useConfig(config => config.clientToken);
  const oauthConfig = useConfig(config => config.oauth);
  const oauthClient = makeOauthClient(clientToken, oauthConfig);

  const [authentication, setAuthentication] = usePersistedState<AuthenticationType>(emptyAuthentication);

  // handlers
  const logout = useCallback(() => {
    setAuthentication(emptyAuthentication);
  }, [setAuthentication]);

  const authenticate = useCallback(
    async (email: string, password: string, remember: boolean) => {
      // retrieve access token, using provided code
      try {
        const oauthValue = await oauthClient.getTokenFromPassword(email, password);
        const accessToken = oauthValue && oauthValue.access_token;
        const jwtContent = jwtDecode<JWTTokenType>(accessToken);
        if (jwtContent.targetId && !/\/users\//.test(jwtContent.targetId)) {
          if (!remember) {
            delete oauthValue.refresh_token;
          }

          setAuthentication({
            grantType: 'password',
            value: oauthValue
          });
        } else {
          setAuthentication(emptyAuthentication);
          throw new Error('Vous ne pouvez pas vous connecter avec votre compte administrateur.');
        }
      } catch (error) {
        // failure, try again
        if (error.message === 'Authentication_failed') {
          throw new Error('Connexion échouée. Veuillez vérifier vos identifiants.');
        } else throw error;
      }
    },
    [oauthClient, setAuthentication]
  );

  const authenticateUsingCode = useCallback(
    async (code: string) => {
      // retrieve access token, using provided code
      try {
        setAuthentication({
          grantType: 'authorization_code',
          value: await oauthClient.getTokenFromAuthorizationCode(code)
        });
      } catch (error) {
        // failure, try again
        console.error('Cannot login user: ', error); // eslint-disable-line no-console
      }
    },
    [oauthClient, setAuthentication]
  );

  const getFreshAccessToken = useCallback(async (): Promise<TokenType | undefined> => {
    // seconds to remove from lifetime, to ensure a valid session everytime
    let secondsBeforeRefresh = 600;

    const refreshToken = authentication.value && authentication.value.refresh_token;

    if (authentication.grantType === 'password') {
      secondsBeforeRefresh = 0;
    }

    const isAccessTokenValid =
      authentication.value && getIsAccessTokenValid(authentication.value.access_token, secondsBeforeRefresh);

    if (isAccessTokenValid) {
      return authentication.value;
    }

    try {
      if (refreshToken) {
        const tokenValue = await oauthClient.getTokenFromRefreshToken(refreshToken);
        setAuthentication({ grantType: authentication.grantType || 'refresh_token', value: tokenValue });
        return tokenValue;
      }

      if (
        (!refreshToken && authentication.grantType === 'password') ||
        (authentication.grantType === null || authentication.grantType === 'client_credentials')
      ) {
        const tokenValue = await oauthClient.getTokenFromClientCredentials();
        setAuthentication({ grantType: 'client_credentials', value: tokenValue });
        return tokenValue;
      }

      return authentication.value;
    } catch (error) {
      const tokenValue = await oauthClient.getTokenFromClientCredentials();
      setAuthentication({ grantType: 'client_credentials', value: tokenValue });
      return tokenValue;
    }
  }, [oauthClient, setAuthentication, authentication.grantType, authentication.value]);

  // return hook values
  const actions = {
    authenticate,
    authenticateUsingCode,
    getFreshAccessToken,
    logout
  };

  const getTargetId = () => {
    const accessToken = (authentication && authentication.value && authentication.value.access_token) || null;
    if (!accessToken) return null;
    const jwtContent = jwtDecode<JWTTokenType>(accessToken);

    return jwtContent.targetId;
  };

  const targetId = getTargetId();

  type ReturnType = [typeof authentication.value, typeof actions, typeof authentication.grantType, typeof targetId];
  return [authentication.value, actions, authentication.grantType, targetId] as ReturnType;
};

const [withAuthentication, useAuthentication] = createProvider(useAuthenticationState);

export { withAuthentication };

export default useAuthentication;
