import { useEffect } from 'react';
import { useEntityManager, useDenormalizedState, useAuthentication } from 'app/hooks';
import { createUnionSchema } from 'app/schemas';
import { ClubType } from 'app/types';
import createProvider from './createProvider';

const clubSchema = createUnionSchema(['clubs']);

const useClub = () => {
  const [{ fetchEntityList }, endpoints] = useEntityManager();
  const [clubs, setClubsResult] = useDenormalizedState<ClubType[]>([], [clubSchema]);
  const [, , , targetId] = useAuthentication();

  useEffect(() => {
    async function fetchClubs() {
      try {
        const data = await fetchEntityList(endpoints.CLUBS, {});
        setClubsResult(data.result);
      } catch (error) {}
    }
    fetchClubs();
  }, [endpoints.CLUBS, fetchEntityList, setClubsResult, targetId]);

  return clubs[0];
};

const [withClub, useProvidedClub] = createProvider(useClub);

export { withClub };

export default useProvidedClub;
