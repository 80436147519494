import { schema } from 'normalizr';

const NETWORK_NODE_SCHEMA = new schema.Entity('networkNodes', {}, { idAttribute: '@id' });
const CLUB_SCHEMA = new schema.Entity('clubs', {}, { idAttribute: '@id' });
const STUDIO_SCHEMA = new schema.Entity('studios', {}, { idAttribute: '@id' });
const STUDIO_LIVE_SCHEMA = new schema.Entity('studiosLive', {}, { idAttribute: 'id' });
const STUDIO_WITH_STATE_SCHEMA = new schema.Entity('studiosWithState', {}, { idAttribute: '@id' });
const COACH_SCHEMA = new schema.Entity('coaches', {}, { idAttribute: '@id' });

export default {
  NetworkNode: NETWORK_NODE_SCHEMA,
  Club: CLUB_SCHEMA,
  Studio: STUDIO_SCHEMA,
  StudioLive: STUDIO_LIVE_SCHEMA,
  StudioWithState: STUDIO_WITH_STATE_SCHEMA,
  Coach: COACH_SCHEMA
};
