import React, { ComponentType, useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Box, Button, Container, Drawer, Hidden, IconButton, Toolbar } from '@material-ui/core';
import { ArrowBack, Menu as MenuIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import AppMenu from './AppMenu';
import AccountButton from './Account/AccountButton';
import Footer from './Footer';
import { useConfig, useWidth } from 'app/hooks';

type RenderFunction = () => React.ReactNode;
type PropTypes = {
  children: React.ReactNode;
  renderTitle?: RenderFunction;
} & React.HTMLAttributes<HTMLElement>;

const useStyles = makeStyles(theme => ({
  contactName: {
    color: '#fff',
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'none'
  },
  menuDrawer: {
    width: 300,
    maxWidth: `calc(100vw - ${theme.spacing(4)}px)`
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  footerLink: {
    ...theme.typography.body2,
    color: theme.palette.getContrastText(theme.palette.secondary.dark),
    textDecoration: 'none'
  },
  footerTitle: {
    color: theme.palette.getContrastText(theme.palette.secondary.dark)
  },
  buttonLogo: {
    display: 'inline-block'
  },
  logo: {
    maxWidth: '100%',
    maxHeight: '46px'
  },
  logoBig: {
    maxWidth: '100%',
    maxHeight: '60px'
  }
}));

const defaultRenderTitle = () => 'Altinnova';

const Layout: ComponentType<PropTypes> = ({ children, renderTitle = defaultRenderTitle, ...props }) => {
  const theme = useConfig(config => config.theme);
  const clientToken = useConfig(config => config.clientToken);

  /** HOOKS **/
  const width = useWidth();
  const classes = useStyles();
  const { location, history } = useReactRouter();

  /** STATES **/
  const [navigationOpened, setNavigationOpened] = useState(false);
  const { withPrev = false, prevLink = null } = location.state || {};

  /** HANDLERS **/
  const toggleNavigation = useCallback(() => setNavigationOpened(!navigationOpened), [navigationOpened]);
  const goBack = prevLink ? () => history.push(prevLink) : () => history.goBack();

  /** EFFECTS **/
  useEffect(() => {
    setNavigationOpened(false);
  }, [location]);

  return (
    <div {...props} className={classes.root}>
      <AppBar position="sticky">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Hidden smUp>
              {withPrev && (
                <IconButton edge="start" color="inherit" aria-label="Go back" onClick={goBack}>
                  <ArrowBack />
                </IconButton>
              )}
              <IconButton edge="start" color="inherit" aria-label="Menu" onClick={toggleNavigation}>
                <MenuIcon />
              </IconButton>
            </Hidden>
            <Box flex={1} display="flex" justifyContent={width === 'xs' ? 'center' : 'flex-start'}>
              <Button component={Link} color="inherit" to="/" className={classes.buttonLogo}>
                <img
                  src={theme.logo}
                  alt="Logo"
                  className={theme.logo_size && theme.logo_size === 'logoBig' ? classes.logoBig : classes.logo}
                />
              </Button>
            </Box>
            <Hidden xsDown>
              <AccountButton />
            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>
      <Hidden smUp>
        <Drawer
          open={navigationOpened}
          classes={{ paper: classes.menuDrawer }}
          ModalProps={{ onBackdropClick: toggleNavigation, onEscapeKeyDown: toggleNavigation }}
        >
          <AppMenu />
        </Drawer>
      </Hidden>
      {children}
      <Hidden xsDown>
        <Footer />
      </Hidden>
    </div>
  );
};

export default Layout;
