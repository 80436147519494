import { useEffect, useState, useCallback, useMemo } from 'react';
import moment from 'moment';
import { useEntityManager, useDenormalizedState, useConnectedContact } from 'app/hooks';
import { createUnionSchema } from 'app/schemas';
import { SubscriptionType } from 'app/types/index';
import createProvider from './createProvider';

const subscriptionSchema = createUnionSchema(['subscriptions']);

const useActiveSubscription = () => {
  const connectedContact = useConnectedContact();

  const [{ fetchEntityList }, endpoints] = useEntityManager();
  const [isLoaded, setIsLoaded] = useState(false);
  const [subscriptions, setSubscriptionsResult] = useDenormalizedState<SubscriptionType[]>([], [subscriptionSchema]);

  const contactId = useMemo(() => {
    return connectedContact && connectedContact['@id'];
  }, [connectedContact]);

  const fetch = useCallback(async () => {
    setIsLoaded(false);
    // retrieve access token, using provided code
    try {
      const data = await fetchEntityList(endpoints.SUBSCRIPTIONS, {
        params: {
          contact: contactId,
          'validThrough[strictly_after]': moment().format('YYYY-MM-DD')
        }
      });
      setSubscriptionsResult(data.result);
      setIsLoaded(true);
    } catch (error) {
      setIsLoaded(true);
    }
  }, [contactId, endpoints.SUBSCRIPTIONS, fetchEntityList, setSubscriptionsResult]);

  useEffect(() => {
    if (contactId) fetch();
  }, [contactId, fetch]);

  const activeSubscription = useMemo(() => {
    return subscriptions.filter(subscription =>
      moment(subscription.validFrom).isSameOrBefore(moment().startOf('day'))
    )[0];
  }, [subscriptions]);

  const activeSubscriptionCount = useMemo(() => {
    return subscriptions.filter(subscription => moment(subscription.validFrom).isSameOrBefore(moment().startOf('day')))
      .length;
  }, [subscriptions]);

  const futureSubscription = useMemo(() => {
    return subscriptions.filter(subscription => moment(subscription.validFrom).isAfter(moment()))[0];
  }, [subscriptions]);

  const data = {
    activeSubscription,
    isLoaded,
    futureSubscription,
    activeSubscriptionCount
  };

  const actions = {
    refresh: fetch
  };

  return [data, actions] as [typeof data, typeof actions];
};

const [withActiveSubscription, useProvidedActiveSubscription] = createProvider(useActiveSubscription);

export { withActiveSubscription };

export default useProvidedActiveSubscription;
