import { schema, Schema } from 'normalizr';
import accountingApiSchemas from './accountingApi';
import bookingSchemas from './bookingApi';
import candidaciesShemas from './candidaciesApi';
import clubSchemas from './clubApi';
import communicationSchemas from './communicationApi';
import contactSchemas from './contactApi';
import counterSchemas from './counterApi';
import customizationSchemas from './customizationApi';
import productSchemas from './productApi';
import subscriptionSchemas from './subscriptionApi';
import referentialSchemas from './referentialApi';
import saleSchemas from './saleApi';
import userSchemas from './userApi';
import municipalitySchemas from './municipalityApi';
import fileSchemas from './fileApi';
import waitingSchemas from './waitingApi';
import identificatorSchemas from './identificatorApi';

const ENTITY_SCHEMAS = {
  ...accountingApiSchemas,
  ...bookingSchemas,
  ...candidaciesShemas,
  ...clubSchemas,
  ...communicationSchemas,
  ...contactSchemas,
  ...counterSchemas,
  ...customizationSchemas,
  ...fileSchemas,
  ...productSchemas,
  ...subscriptionSchemas,
  ...referentialSchemas,
  ...saleSchemas,
  ...userSchemas,
  ...municipalitySchemas,
  ...waitingSchemas,
  ...identificatorSchemas
};

export type EntityRef = { id: string; schema: keyof typeof ENTITY_SCHEMAS };

const getEntityType = (schemaKey: string) => {
  const entityTypeEntry = Object.entries(ENTITY_SCHEMAS).find(([, entitySchema]) => entitySchema.key === schemaKey);

  return entityTypeEntry && entityTypeEntry[0];
};

type UnionEntitySchema = Record<string, Schema>;

const createUnionEntitySchema = (schemaKey: string, definition: {}) => {
  const entityType = getEntityType(schemaKey);
  if (!entityType) return {};

  return { [entityType]: new schema.Entity(schemaKey, definition) } as UnionEntitySchema;
};

export const createUnionSchema = (...args: Array<[string, {}?]>) => {
  const union = args.reduce((memo, arg) => ({ ...memo, ...createUnionEntitySchema(arg[0], arg[1] || {}) }), {});
  return new schema.Union(union, '@types');
};

export const createSchema = (key: string, definition?: {}, options: {} = { idAttribute: '@id' }) =>
  new schema.Entity(key, definition, options);

export const RESULT_SCHEMA = new schema.Union(ENTITY_SCHEMAS, '@type');
