import { useCallback, useEffect, useMemo, useState } from 'react';
import { useEntityManager, useDenormalizedState } from 'app/hooks';
import { createUnionSchema } from 'app/schemas';
import { StudioType } from 'app/types';
import createProvider from './createProvider';

const studioSchema = createUnionSchema(['studios']);

const useStudioWithState = () => {
  const [{ fetchEntityList }, endpoints] = useEntityManager();
  const [studios, setStudiosWithStateResult] = useDenormalizedState<StudioType[]>([], [studioSchema]);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchStudiosWithState = useCallback(async () => {
    try {
      setStudiosWithStateResult([]);
      const data = await fetchEntityList(endpoints.STUDIOS_WITH_STATE, {
        params: {
          pagination: false,
          archived: false
        }
      });
      setStudiosWithStateResult(data.result);
      setIsLoaded(true);
    } catch (error) {
      setIsLoaded(false);
    }
  }, [endpoints.STUDIOS_WITH_STATE, fetchEntityList, setStudiosWithStateResult]);

  useEffect(() => {
    fetchStudiosWithState();
  }, [fetchStudiosWithState]);

  const currentStudioInWaitingList: any = useMemo(() => {
    let value = undefined;
    if (studios.length) {
      studios.forEach(studio => {
        if ('in_waiting_list' === studio.state) {
          value = studio;
        }
      });
    }
    return value;
  }, [studios]);

  const [displaySubscriptionWarning, countStateSubscription] = useMemo(() => {
    let value = false;
    let count = 0;
    if (studios.length) {
      studios.forEach(studio => {
        if ('can_subscribe' === studio.state) {
          value = true;
          count++;
        }
      });
    }
    return [value, count];
  }, [studios]);

  const [displayWaitingListWarning, countStateWaitingList] = useMemo(() => {
    let value = false;
    let count = 0;
    if (studios.length) {
      studios.forEach(studio => {
        if ('can_waiting_list' === studio.state) {
          value = true;
          count++;
        }
      });
    }
    return [value, count];
  }, [studios]);

  const displayCurrentCandidate = useMemo(() => {
    let value = false;
    if (studios.length) {
      studios.forEach(studio => {
        if ('in_candidacy' === studio.state) {
          value = true;
        }
      });
    }
    return value;
  }, [studios]);

  const [displayCandidateWarning, countStateCandidacy] = useMemo(() => {
    let value = false;
    let count = 0;
    if (studios.length) {
      studios.forEach(studio => {
        if ('can_candidacy' === studio.state) {
          value = true;
          count++;
        }
      });
    }
    return [value, count];
  }, [studios]);

  const elementByState = useMemo(() => {
    return {
      can_candidacy: countStateCandidacy,
      can_waiting_list: countStateWaitingList,
      can_subscribe: countStateSubscription
    };
  }, [countStateCandidacy, countStateSubscription, countStateWaitingList]);

  const data = {
    currentStudioInWaitingList,
    displayCandidateWarning,
    displayCurrentCandidate,
    displaySubscriptionWarning,
    displayWaitingListWarning,
    elementByState,
    studios,
    isLoaded
  };

  const actions = {
    refresh: fetchStudiosWithState
  };

  return [data, actions] as [typeof data, typeof actions];
};

const [withStudioWithState, useProvidedStudioWithState] = createProvider(useStudioWithState);

export { withStudioWithState };

export default useProvidedStudioWithState;
