import { useEffect, useMemo } from 'react';
import { useAuthentication, useEntityManager, useDenormalizedState } from 'app/hooks';
import { createUnionSchema } from 'app/schemas';
import { ContactUserType, ContactType } from 'app/types/index';
import createProvider from './createProvider';

const contactUserSchema = createUnionSchema(['contactUsers']);
const contactSchema = createUnionSchema(['contacts']);

const useConnectedContact = () => {
  const [, , , targetId] = useAuthentication();
  const [{ fetchEntity }] = useEntityManager();
  const [contactUser, setContactUserResult] = useDenormalizedState<ContactUserType>({}, contactUserSchema);
  const [contact, setContactResult] = useDenormalizedState<ContactType>({}, contactSchema);

  const contactUserId = useMemo(() => {
    return contactUser && contactUser['@id'];
  }, [contactUser]);

  const contactId = useMemo(() => {
    return contact && contact['@id'];
  }, [contact]);

  useEffect(() => {
    async function fetchContactUser() {
      try {
        const data = await fetchEntity(targetId);
        setContactUserResult(data.result);
      } catch (error) {}
    }
    if (targetId && contactUserId !== targetId) fetchContactUser();
    if (!targetId) {
      setContactUserResult({});
      setContactResult({});
    }
  }, [contactUserId, fetchEntity, setContactResult, setContactUserResult, targetId]);

  useEffect(() => {
    async function fetchContact() {
      try {
        const data = await fetchEntity(contactUser.contactId);
        setContactResult(data.result);
      } catch (error) {}
    }
    if (contactUser && contactUser.contactId !== contactId) fetchContact();
  }, [contactId, contactUser, fetchEntity, setContactResult]);

  return contact;
};

const [withConnectedContact, useProvidedConnectedContact] = createProvider(useConnectedContact);

export { withConnectedContact };

export default useProvidedConnectedContact;
