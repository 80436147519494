import { schema } from 'normalizr';

const SALE_SCHEMA = new schema.Entity('sales', {}, { idAttribute: '@id' });
const ARTICLE_SCHEMA = new schema.Entity('articles', {}, { idAttribute: '@id' });
const ARTICLE_BEHAVIOR_SCHEMA = new schema.Entity('articleBehaviors', {}, { idAttribute: '@id' });
const PROCEEDING_SCHEMA = new schema.Entity('proceedings', {}, { idAttribute: '@id' });

ARTICLE_SCHEMA.define({
  articleBehaviors: [ARTICLE_BEHAVIOR_SCHEMA]
});

export default {
  Article: ARTICLE_SCHEMA,
  ArticleBehavior: ARTICLE_BEHAVIOR_SCHEMA,
  Sale: SALE_SCHEMA,
  Proceeding: PROCEEDING_SCHEMA
};
