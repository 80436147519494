import { useCallback, useContext } from 'react';
import useReactRouter from 'use-react-router';
import { SubscriptionsWorkflowContext } from 'app/contexts';
import * as base64 from 'app/helpers/base64';
import { useEntityManager, useClub } from 'app/hooks';

const usePayment = () => {
  /** CONTEXTS **/
  const { currentStudioBoxAssignationId } = useContext(SubscriptionsWorkflowContext);
  const club = useClub();
  const { history } = useReactRouter();
  const [{ postEntity }, endpoints] = useEntityManager();

  const initializePayment = useCallback(
    async (saleId, webRedirectUrl, onError, item = '', isExtension = false) => {
      if ('CBWeb' === item) {
        try {
          const result = await postEntity(`${saleId}/transitions`, {
            data: {
              transition: 'validate'
            }
          });
          const sale = result.response.data;
          let transactionResult = await postEntity(endpoints.PAYMENT_TRANSACTIONS, {
            data: {
              amount: sale.totalTI,
              currency: sale.priceCurrency,
              invoice: sale.invoiceId,
              contactId: sale.contactId,
              clubId: club['@id'],
              clubCode: club.code,
              webRedirectUrl: `${webRedirectUrl}/${base64.encode(sale.invoiceId)}`
            }
          });
          const transactionId = transactionResult.response.data['@id'];
          transactionResult = await postEntity(`${transactionId}/do_payment`, {
            data: {}
          });
          const redirectURL = transactionResult.response.data.redirectUrl;
          window.location.href = redirectURL;
        } catch {
          onError();
        }
      }

      if ('cheque' === item && currentStudioBoxAssignationId) {
        try {
          await postEntity(`${saleId}/transitions`, {
            data: {
              transition: 'validate'
            }
          });

          if (isExtension) {
            history.push('/subscriptions/extensions/payment-by-check');
          } else {
            const studioBoxAssignationId = currentStudioBoxAssignationId.substring(
              currentStudioBoxAssignationId.lastIndexOf('/') + 1
            );
            await postEntity(`${endpoints.STUDIO_BOX_ASSIGNATIONS}/${studioBoxAssignationId}/extend`, {
              data: {}
            });

            history.push('/subscriptions/payment-by-check');
          }
        } catch {
          onError();
        }
      }
    },
    [
      club,
      currentStudioBoxAssignationId,
      endpoints.PAYMENT_TRANSACTIONS,
      endpoints.STUDIO_BOX_ASSIGNATIONS,
      history,
      postEntity
    ]
  );

  const initializeFreePayment = useCallback(
    async (saleId, onError, isExtension = false) => {
      try {
        await postEntity(`${saleId}/transitions`, {
          data: {
            transition: 'validate'
          }
        });

        if (isExtension) {
          history.push('/subscriptions/extensions/confirmation/free');
        } else {
          history.push('/subscriptions/confirmation/free');
        }
      } catch {
        onError();
      }
    },
    [
      club,
      currentStudioBoxAssignationId,
      endpoints.PAYMENT_TRANSACTIONS,
      endpoints.STUDIO_BOX_ASSIGNATIONS,
      history,
      postEntity
    ]
  );

  return {
    initializePayment,
    initializeFreePayment
  };
};

export default usePayment;
