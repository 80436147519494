/* eslint-disable no-process-env */

import { useMemo } from 'react';
import logoParis from 'app/assets/paris/logo.png';
import logoSaintCloud from 'app/assets/saintcloud/logo.png';
import logoSaintCloudFooter from 'app/assets/saintcloud/logo_footer.png';
import faviconSaintCloud from 'app/assets/saintcloud/favicon.jpg';
import logoGap from 'app/assets/gap/logo.png';
import faviconGap from 'app/assets/gap/favicon.png';

type ClientConfig = {
  theme: {
    textColor?: string;
    primaryColor: string;
    secondaryColor?: string;
    headerColor?: string;
    footerColor?: string;
    logo: string;
    logo_size?: string;
    logoFooter?: string;
    favicon?: string;
    title?: string;
    nameSender?: string;
    emailSender?: string;
    emailTarget: string;
    targetType: string;
    contactModelId: string;
  };
  rules: {
    availableAccessTypes: Array<
      'sofialocks_phone' | 'sofialocks_card' | 'identificator' | 'remote' | 'navigo_badge' | 'qrcode'
    >;
    availablePaymentMeans: Array<'cheque' | 'CBWeb'>;
    availableWaitingList: 'none' | 'waiting' | 'active';
    candidateOptions?: Array<'child_seat' | 'electrical_outlet'>;
    minimumAge: number;
    postalCodeRule: RegExp;
    postalCodeErrorMessage: string;
    provideProofOfAddress: boolean;
    subscriptionType: string;
    withSubscriptionExtension: boolean;
    withReservation: boolean;
  };
  i18n: {
    accessTypes: {
      [key: string]: {
        label: string;
        description?: string;
      };
    };
    candidateOptions?: {
      [key: string]: {
        label: string;
      };
    };
    paymentMeans: {
      [key: string]: {
        label: string;
      };
    };
  };
  footer: {
    contact: boolean;
    phone?: string;
    mail?: string;
    logo_url?: string;
    address?: {
      name: string;
      address: string;
      postal_code: string;
      city: string;
    };
  };
  menu: {
    service: string;
    offers: string;
    boxes: string;
  };
  legalNotice: {
    active: boolean;
    url_prod: string;
    city: string;
    service_name?: string;
    publication_director: string;
    editorial_manager?: string;
  };
  mapDisplay: {
    availablePlaces: boolean;
    remainingSubscriptions: boolean;
  };
};

type ClientToken = 'paris' | 'saintcloud' | 'gap' | 'default';

const clientConfigs: Record<ClientToken, ClientConfig> = {
  paris: {
    theme: {
      primaryColor: '#00A5AF',
      secondaryColor: '#fff',
      footerColor: '#071f32',
      headerColor: '#FFFFFF',
      logo: logoParis,
      logoFooter: logoParis,
      title: 'Abris à vélos sécurisés de la ville de Paris',
      favicon: 'https://www.paris.fr/favicon.ico',
      emailTarget: '/paris/users/4',
      targetType: 'user',
      contactModelId: '/paris/email_models/3',
      emailSender: 'altinnova-paris@stadline.com',
      nameSender: 'Ville de Paris'
    },
    rules: {
      availableAccessTypes: ['sofialocks_card', 'sofialocks_phone'],
      availablePaymentMeans: ['CBWeb', 'cheque'],
      availableWaitingList: 'active',
      candidateOptions: ['child_seat'],
      minimumAge: 14,
      postalCodeRule: /^75[0-9]{3}$/,
      postalCodeErrorMessage: 'Le code postal est requis et doit commencer par 75',
      provideProofOfAddress: true,
      subscriptionType: 'studio',
      withSubscriptionExtension: true,
      withReservation: false
    },
    i18n: {
      accessTypes: {
        sofialocks_card: {
          label: 'badge',
          description: '(Le badge sera délivré sous 7 jours par voie postale)'
        },
        sofialocks_phone: {
          label: 'application mobile',
          description: "(L'accès à votre abri sera effectif 30 minutes après la validation de votre paiement)"
        }
      },
      candidateOptions: {
        child_seat: {
          label: 'Siège enfant'
        }
      },
      paymentMeans: {
        CBWeb: {
          label: 'carte bancaire'
        },
        cheque: {
          label: 'chèque'
        }
      }
    },
    footer: {
      contact: false
    },
    menu: {
      service: 'Découvrir le service',
      offers: 'Découvrir notre offre',
      boxes: 'Découvrir nos abris'
    },
    legalNotice: {
      active: true,
      url_prod: 'https://www.abris-securises-velos.paris.fr/',
      city: 'la ville de PARIS',
      publication_director: "M. Le Président d'ALTINNOVA"
    },
    mapDisplay: {
      availablePlaces: false,
      remainingSubscriptions: true
    }
  },
  saintcloud: {
    theme: {
      primaryColor: '#00405e',
      secondaryColor: '#fff',
      footerColor: '#00405e',
      logo: logoSaintCloud,
      logoFooter: logoSaintCloudFooter,
      title: 'Véligo à Saint-Cloud',
      favicon: faviconSaintCloud,
      emailTarget: '/saintcloud/users/41',
      targetType: 'user',
      contactModelId: '/saintcloud/email_models/14'
    },
    rules: {
      availableAccessTypes: ['identificator', 'navigo_badge'],
      availablePaymentMeans: ['CBWeb'],
      availableWaitingList: 'none',
      minimumAge: 16,
      postalCodeRule: /^[\d]{5}$/,
      postalCodeErrorMessage: 'Le code postal est requis',
      provideProofOfAddress: false,
      subscriptionType: 'studio',
      withSubscriptionExtension: true,
      withReservation: false
    },
    i18n: {
      accessTypes: {
        identificator: {
          label: 'Badge',
          description: '(Le badge sera délivré sous 7 jours par voie postale)'
        },
        navigo_badge: {
          label: 'Badge (Navigo)'
        }
      },
      paymentMeans: {
        CBWeb: {
          label: 'carte bancaire'
        }
      }
    },
    footer: {
      contact: true,
      mail: 'saintcloud@monabrivelo.com'
    },
    menu: {
      service: 'Découvrir le service',
      offers: 'Découvrir notre offre',
      boxes: 'Découvrir notre abri'
    },
    legalNotice: {
      active: true,
      url_prod: 'www.veligo.saintcloud.fr',
      city: 'la ville de Saint-Cloud',
      publication_director: 'ERIC BERDOATI – Maire',
      editorial_manager: 'ERIC BERDOATI – Maire'
    },
    mapDisplay: {
      availablePlaces: true,
      remainingSubscriptions: true
    }
  },
  gap: {
    theme: {
      primaryColor: '#009DDE',
      secondaryColor: '#7bc13f',
      logo: logoGap,
      favicon: faviconGap,
      logo_size: 'logoBig',
      title: 'Les abris vélos',
      emailTarget: '/gap/users/48',
      targetType: 'user',
      contactModelId: '/gap/email_models/22'
    },
    rules: {
      availableAccessTypes: ['identificator', 'qrcode'],
      availablePaymentMeans: ['CBWeb'],
      availableWaitingList: 'none',
      minimumAge: 16,
      postalCodeRule: /^[\d]{5}$/,
      postalCodeErrorMessage: 'Le code postal est requis',
      provideProofOfAddress: false,
      subscriptionType: 'all',
      withSubscriptionExtension: true,
      withReservation: false
    },
    i18n: {
      accessTypes: {
        identificator: {
          label: 'Badge',
          description: '(Le badge sera délivré sous 7 jours par voie postale)'
        },
        qrcode: {
          label: 'QR Code'
        }
      },
      paymentMeans: {
        CBWeb: {
          label: 'carte bancaire'
        }
      }
    },
    footer: {
      contact: false
    },
    menu: {
      service: 'Découvrir le service',
      offers: 'Découvrir notre offre',
      boxes: 'Découvrir nos abris'
    },
    legalNotice: {
      active: true,
      url_prod: '[URL DU SITE PROD]',
      city: 'la ville de Gap',
      service_name: 'Direction de la Communication',
      publication_director: "Roger DIDIER Président de la Communauté d'Agglomération Gap-Tallard-Durance",
      editorial_manager: 'Alexandre DELIA Chef de projet communication'
    },
    mapDisplay: {
      availablePlaces: false,
      remainingSubscriptions: false
    }
  },
  default: {
    theme: {
      primaryColor: '#b6b2b0',
      secondaryColor: '#fff',
      logo: '',
      title: 'Les abris vélos',
      emailTarget: '',
      targetType: 'user',
      contactModelId: ''
    },
    rules: {
      availableAccessTypes: [],
      availablePaymentMeans: [],
      availableWaitingList: 'none',
      minimumAge: 16,
      postalCodeRule: /^[\d]{5}$/,
      postalCodeErrorMessage: 'Le code postal est requis',
      provideProofOfAddress: false,
      subscriptionType: 'studio',
      withSubscriptionExtension: false,
      withReservation: false
    },
    i18n: {
      accessTypes: {
        identificator: {
          label: 'Badge',
          description: '(Le badge sera délivré sous 7 jours par voie postale)'
        }
      },
      paymentMeans: {
        CBWeb: {
          label: 'carte bancaire'
        }
      }
    },
    footer: {
      contact: false
    },
    menu: {
      service: 'Découvrir le service',
      offers: 'Découvrir notre offre',
      boxes: 'Découvrir nos abris'
    },
    legalNotice: {
      active: false,
      url_prod: '[URL DU SITE PROD]',
      city: '[NOM DE LA REGION OU VILLE]',
      service_name: '[NOM DU SERVICE]',
      publication_director: 'Prénom Nom + poste',
      editorial_manager: 'Prénom Nom + poste'
    },
    mapDisplay: {
      availablePlaces: false,
      remainingSubscriptions: false
    }
  }
};

const getEnv = (name: string) => {
  // @ts-ignore
  if (window.env !== undefined && window.env[name] !== undefined) {
    // @ts-ignore
    return window.env[name];
  }

  return process.env[name];
};

const envConfig = {
  clientToken: getEnv('REACT_APP_CLIENT_TOKEN') as ClientToken,
  api: {
    url: getEnv('REACT_APP_API_URL')
  },
  oauth: {
    url: getEnv('REACT_APP_API_URL'),
    clientId: getEnv('REACT_APP_OAUTH_CLIENT_ID'),
    clientSecret: getEnv('REACT_APP_OAUTH_CLIENT_SECRET'),
    anonymousClientId: getEnv('REACT_APP_OAUTH_ANONYMOUS_CLIENT_ID'),
    anonymousClientSecret: getEnv('REACT_APP_OAUTH_ANONYMOUS_CLIENT_SECRET')
  },
  recaptcha: {
    siteKey: getEnv('REACT_APP_RECAPTCHA_SITE_KEY')
  },
  googleMaps: {
    apiKey: getEnv('REACT_APP_MAPS_API_KEY')
  }
};

type ConfigType = typeof envConfig & ClientConfig;
type SelectorType<T = any> = (config: ConfigType) => T;
type UseConfigType = <T = ConfigType>(selector?: SelectorType<T>) => T;

const defaultSelector: SelectorType = config => config;
const useConfig: UseConfigType = (selector = defaultSelector) => {
  const clientConfig =
    clientConfigs[envConfig.clientToken] === undefined
      ? clientConfigs['default']
      : clientConfigs[envConfig.clientToken];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => selector({ ...envConfig, ...clientConfig }), [envConfig, clientConfig]);
};

export default useConfig;
