/**
 * SubscriptionsWorkflowContext.
 *
 * @description Manage subscription workflow.
 */

import React, { createContext, ReactNode, useEffect, useState } from 'react';
import createPersistedState from 'use-persisted-state';
import { useCurrentStudioBoxAssignation, useDenormalizedState, useEntityManager } from 'app/hooks';
import { createUnionSchema } from 'app/schemas';
import { ProductType, StudioBoxType, StudioType } from 'app/types';

type SubscriptionsWorkflow = {
  clearCurrentProduct: () => void;
  currentOfferId?: string;
  currentProduct: ProductType | null;
  currentStartDate?: string;
  currentStudio?: StudioType;
  currentStudioBox?: StudioBoxType;
  currentStudioBoxAssignationId?: string;
  setCurrentOfferId: (offerId?: string) => void;
  setCurrentProduct: (product: ProductType | null) => void;
  setCurrentStartDate: (date?: string) => void;
  setCurrentStudio: (studio?: StudioType) => void;
  setCurrentStudioBox: (studioBox?: StudioBoxType) => void;
  setCurrentStudioBoxAssignationId: (studioBoxAssignation?: string) => void;
};

type SubscriptionsWorkflowProps = {
  children: ReactNode;
};

const initialSubscriptionsWorkflowContext: SubscriptionsWorkflow = {
  clearCurrentProduct: () => {},
  currentProduct: null,
  setCurrentOfferId: (offerId?: string) => {},
  setCurrentProduct: (product: ProductType | null) => {},
  setCurrentStartDate: (date?: string) => {},
  setCurrentStudio: (studio?: StudioType) => {},
  setCurrentStudioBox: (studioBox?: StudioBoxType) => {},
  setCurrentStudioBoxAssignationId: (studioBoxAssignation?: string) => {}
};

export const SubscriptionsWorkflowContext = createContext<SubscriptionsWorkflow>(initialSubscriptionsWorkflowContext);
SubscriptionsWorkflowContext.displayName = 'SubscriptionsWorkflowContext';

const studioBoxSchema = createUnionSchema(['studioBoxes']);
const studioSchema = createUnionSchema(['studios']);
const usePersistedState = createPersistedState('currentProduct');

export const SubscriptionsWorkflowProvider = ({ children }: SubscriptionsWorkflowProps): JSX.Element => {
  const [currentOfferId, setCurrentOfferId] = useState<string>();
  const [currentProduct, setCurrentProduct] = usePersistedState<ProductType | null>(null);
  const [currentStartDate, setCurrentStartDate] = useState<string>();
  const [currentStudio, setCurrentStudio] = useState<StudioType>();
  const [currentStudioBox, setCurrentStudioBox] = useState<StudioBoxType>();
  const [currentStudioBoxAssignationId, setCurrentStudioBoxAssignationId] = useState<string>();
  const clearCurrentProduct = () => setCurrentProduct(null);

  // If current studio box assignation already exists
  const [{ fetchEntity }] = useEntityManager();
  const [{ studioBoxAssignation, isLoaded: isLoadedStudioBoxAssignation }] = useCurrentStudioBoxAssignation();
  const studioBoxAssignationId = studioBoxAssignation && studioBoxAssignation['@id'];
  const studioBoxId = studioBoxAssignation && studioBoxAssignation.studioBox;

  // Update currentStudioBoxAssignationId
  useEffect(() => {
    if (isLoadedStudioBoxAssignation) setCurrentStudioBoxAssignationId(studioBoxAssignationId);
  }, [isLoadedStudioBoxAssignation, setCurrentStudioBoxAssignationId, studioBoxAssignationId]);

  // - fetch Studio Box
  const [studioBox, setStudioBoxResult] = useDenormalizedState<StudioBoxType>({}, studioBoxSchema);
  const [isLoadedStudioBox, setLoadedStudioBox] = useState<boolean>();
  useEffect(() => {
    async function fetchStudioBox() {
      setLoadedStudioBox(false);
      try {
        const data: { result: any } = await fetchEntity(studioBoxId);
        setStudioBoxResult(data.result);
        setLoadedStudioBox(true);
      } catch (error) {}
    }
    if (isLoadedStudioBoxAssignation && studioBoxId) fetchStudioBox();
  }, [fetchEntity, isLoadedStudioBoxAssignation, setLoadedStudioBox, setStudioBoxResult, studioBoxId]);
  // Update currentStudioBox
  useEffect(() => {
    if (isLoadedStudioBox && isLoadedStudioBoxAssignation && studioBox) setCurrentStudioBox(studioBox);
  }, [isLoadedStudioBox, isLoadedStudioBoxAssignation, setCurrentStudioBox, studioBox]);

  // - fetch Studio
  const [studio, setStudioResult] = useDenormalizedState<StudioType>({}, studioSchema);
  const [isLoadedStudio, setLoadedStudio] = useState<boolean>();
  const studioId = studioBox && studioBox.studio;
  useEffect(() => {
    async function fetchStudio() {
      setLoadedStudio(false);
      try {
        const data: { result: any } = await fetchEntity(studioId);
        setStudioResult(data.result);
        setLoadedStudio(true);
      } catch (error) {}
    }
    if (!currentStudio && isLoadedStudioBox && isLoadedStudioBoxAssignation && studioId) fetchStudio();
  }, [
    currentStudio,
    fetchEntity,
    isLoadedStudioBox,
    isLoadedStudioBoxAssignation,
    setLoadedStudio,
    setStudioResult,
    studioId
  ]);
  // Update currentStudio
  useEffect(() => {
    if (!currentStudio && isLoadedStudio && isLoadedStudioBoxAssignation && studio) setCurrentStudio(studio);
  }, [currentStudio, isLoadedStudio, isLoadedStudioBoxAssignation, setCurrentStudio, studio]);

  return (
    <SubscriptionsWorkflowContext.Provider
      value={{
        clearCurrentProduct,
        currentOfferId,
        currentProduct,
        currentStartDate,
        currentStudio,
        currentStudioBox,
        currentStudioBoxAssignationId,
        setCurrentOfferId,
        setCurrentProduct,
        setCurrentStartDate,
        setCurrentStudio,
        setCurrentStudioBox,
        setCurrentStudioBoxAssignationId
      }}
    >
      {children}
    </SubscriptionsWorkflowContext.Provider>
  );
};
