import { schema } from 'normalizr';

const ACCOUNTING_CONTACT_TAG_SCHEMA = new schema.Entity('accountingContactTags', {}, { idAttribute: '@id' });
const INVOICE_SCHEMA = new schema.Entity('invoices', {}, { idAttribute: '@id' });
const INVOICE_LINE_SCHEMA = new schema.Entity('invoiceLines', {}, { idAttribute: '@id' });
const INCIDENT_SCHEMA = new schema.Entity('incidents', {}, { idAttribute: '@id' });
const MANDATE_SCHEMA = new schema.Entity('mandates', {}, { idAttribute: '@id' });
const PAYABLE_OBJECT_SCHEMA = new schema.Entity('payableObjects', {}, { idAttribute: '@id' });
const INVOICE_DUE_SCHEMA = new schema.Entity('invoiceDues', {}, { idAttribute: '@id' });

export default {
  AccountingContactTag: ACCOUNTING_CONTACT_TAG_SCHEMA,
  Invoice: INVOICE_SCHEMA,
  InvoiceLine: INVOICE_LINE_SCHEMA,
  Incident: INCIDENT_SCHEMA,
  Mandate: MANDATE_SCHEMA,
  PayableObject: PAYABLE_OBJECT_SCHEMA,
  InvoiceDue: INVOICE_DUE_SCHEMA
};
