import { useRef, useEffect } from 'react';
import axios from 'axios';
import useAuthentication from './useAuthentication';
import useConfig from './useConfig';

const useApiClient = () => {
  const { current: client } = useRef(axios.create());
  const [, { getFreshAccessToken }] = useAuthentication();
  const apiConfig = useConfig(config => config.api);

  useEffect(() => {
    const interceptorIndex = client.interceptors.request.use(async requestConfig => {
      const token = await getFreshAccessToken();

      return {
        ...requestConfig,
        baseURL: apiConfig.url,
        headers: { ...requestConfig.headers, Authorization: token && `Bearer ${token.access_token}` }
      };
    });

    return () => {
      client.interceptors.request.eject(interceptorIndex);
    };
  });

  return client;
};

export default useApiClient;
